import React from "react"
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Link as LinkExt,
  Text,
  SimpleGrid,
} from "@chakra-ui/react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Container,
  Layout,
  Section,
  Main,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import { SecondaryPagesHero, Seo } from "../../../components/elements"
import { Card } from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"

const PodcastShow = ({ data, pageContext }) => {
  const page = data.datoCmsCancerPodcastShow
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <Seo
        title={page.seo.title || page.title}
        description={page.seo.description || " "}
        image={page.seo.image && page.seo.image.url}
      />

      <SecondaryPagesHero page={page}>
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={page.title}
        />
      </SecondaryPagesHero>

      <Main>
        <Section>
          <Container>
            <Box as="h2">{page.title}</Box>
            <Text
              sx={{ width: ["full"] }}
              dangerouslySetInnerHTML={{
                __html: page.introContentNode.childMarkdownRemark.html,
              }}
            />

            <Box as="h3">Episodes</Box>

            <SimpleGrid columns={[1]} spacing={[0, 0, 6]}>
              {page.podcastEpisodes.map(({ ...episode }) => (
                <Card key={episode.id} py={3}>
                  <Box p={[3, 3, 6]}>
                    <Flex flexWrap="wrap" mx={[0, 0, -3]}>
                      <Box
                        px={[0, 0, 3]}
                        mx="auto"
                        mb={[6, 6, 0]}
                        textAlign="center"
                        width={["full", "full", 1 / 3]}
                      >
                        {episode.episodeImage && (
                          <GatsbyImage
                            image={episode.episodeImage.gatsbyImageData}
                            height={["140px", "180px", "220px"]}
                            mx="auto"
                            width={["140px", "180px", "220px"]}
                          />
                        )}
                      </Box>
                      <Box px={[0, 0, 3]} width={["full", "full", 2 / 3]}>
                        <Box as="p" fontSize="sm" mb={0}>
                          {episode.episodeDate}
                        </Box>
                        <Box as="h4" mb={6}>
                          {episode.episodeTitle}
                        </Box>
                        <Text
                          sx={{ width: ["full"] }}
                          dangerouslySetInnerHTML={{
                            __html:
                              episode.episodeDescriptionNode.childMarkdownRemark
                                .html,
                          }}
                        />
                        <ButtonGroup
                          variant="outline"
                          size="sm"
                          spacing="3"
                          sx={{ "a:link": { textDecoration: "none" } }}
                        >
                          <LinkExt href={episode.listenOnlineLink}>
                            <Button as="span" variant="solid">
                              Listen Online
                            </Button>
                          </LinkExt>
                          {episode.episodeTranscript && (
                            <LinkExt href={episode.episodeTranscript.url}>
                              <Button as="span">Episode Transcript</Button>
                            </LinkExt>
                          )}
                        </ButtonGroup>
                      </Box>
                    </Flex>
                  </Box>
                </Card>
              ))}
            </SimpleGrid>
          </Container>
        </Section>
      </Main>
    </Layout>
  )
}

export default PodcastShow

export const query = graphql`
  query podcastShowPageQuery($slug: String!) {
    datoCmsCancerPodcastShow(slug: { eq: $slug }) {
      id
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { ar: "1600:720", fit: "crop", crop: "focalpoint" }
        )
      }
      title
      slug
      introContentNode {
        childMarkdownRemark {
          html
        }
      }
      podcastEpisodes {
        listenOnlineLink
        id
        episodeDate(formatString: "MM/DD/YYYY")
        episodeTitle
        episodeTranscript {
          url
        }
        episodeDescriptionNode {
          childMarkdownRemark {
            html
          }
        }
        episodeImage {
          gatsbyImageData
        }
        allEpisodesLink
      }
      seo {
        description
        title
        image {
          url
        }
      }
    }
  }
`
